// @ts-nocheck
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import st from "./Layout.module.scss";
import React from "react";
import { observer } from "mobx-react";


const BarCharts = observer(({data = []}) => {
    const [dataBarCharts, setBarCharts] = React.useState([]);

    React.useEffect(() => {
        if (data.length) {
            setBarCharts(data.map((item, index) => {
                return {name: new Date(2020, item.month - 1, 10).toLocaleString('En', { month: 'short' }),
                    value: item.balance_usdt,
                    value2: item.balance_usdt_with_profit,
                }
            }));
        }
    }, [data]);

    const CustomTooltip = (props: any) => {
        const { payload } = props;

        if (props.active && payload && payload.length) {
            return (
                payload.map((entry: any, index: number) => (
                    <div key={`item-${index}`} className={st.customTooltip}>
                        {`${entry.name}: $${entry.value}`}
                    </div>
                ))
            );
        }

        return null;
    };

    const customLegend = (props: any) => {
        const { payload } = props;

        return (
            <ul>
                {payload.map((entry: any, index: number) => (
                    <li key={`item-${index}`} className={st.legend}>
                        <svg width="8" height="8" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="7.29162" cy="7.33318" r="6.83333" fill={entry.color} />
                        </svg>
                        <span style={{ color: entry.color }}>{entry.value}</span>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={dataBarCharts}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid vertical={false} stroke="#B3BEC3" strokeOpacity="0.2" />
                <XAxis dataKey="name" />
                <YAxis axisLine={false} />
                <Tooltip content={<CustomTooltip />} />
                <Legend align="left" verticalAlign="top" iconType="cirlce" content={customLegend} />
                <Bar name="Balance" maxBarSize={30} dataKey="value" fill="#7e7e7e" />
                <Bar name="Balance with Profit" maxBarSize={30} dataKey="value2" fill="#ffffff" />
            </BarChart>
        </ResponsiveContainer>
    );
});

export default BarCharts;
