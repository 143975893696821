// @ts-nocheck
import React from "react";
import st from "./Table.module.scss";
import { Button, TableContainer } from "@mui/material";
import classNames from "classnames";


const TableGrid: React.FC = ({data = []}) => {
    return (
        <TableContainer>
            <div aria-label="caption table" className={st.table}>
                <div className={st.head}>
                    <div className={st.th}>Type of invest</div>
                    <div className={st.th}>Balance</div>
                    <div className={st.th}>Change Amount</div>
                    <div className={st.th}>Change, %</div>
                    <div className={st.th}>More</div>
                </div>

                <div className={st.body}>
                    {data.map((row, index) => (
                        <div key={'row' + index} className={st.tr}>
                            <div className={st.td}>{row.pair}</div>

                            <div className={st.td}>${row.balance}</div>

                            <div className={classNames(st.td, row.change_net > 0 ? st.td_green : (row.change_net < 0 ? st.td_red : ''))}>
                                ${row.change_net}
                            </div>

                            <div className={classNames(st.td, row.change_net_percentage > 0 ? st.td_green : (row.change_net_percentage < 0 ? st.td_red : ''))}>
                                {row.change_net_percentage > 0 ? '+' : ''}{row.change_net_percentage}%
                            </div>

                            <div className={st.td}>
                                <Button size="small" variant="outlined">Share</Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </TableContainer>
    );
};

export default TableGrid;
