// @ts-nocheck
import * as React from "react";
import DataStore from "../../store/DataStore";
import Grid from "@mui/material/Grid";
import st from "./Dashboard.module.scss";
import TotalInfo from "../TotalInfo/TotalInfo";
import { Box, Button, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import ChartLayout from "../Chart/Layout";

import { ReactComponent as ChartIcon } from "../../assets/images/icons/chart.svg";
import { ReactComponent as StatIcon } from "../../assets/images/icons/stat.svg";
import { useDispatch } from "react-redux";
import { changeHeaderButton } from "../../redux/actions/header";
import { useNavigate } from "react-router-dom";
import { NonAuthRoutes } from "../../routes/routes";
import PieCharts from "../Chart/Pie";
import AreaCharts from "../Chart/Area";
import { observer } from "mobx-react";
import { neatNumber } from "../../helpers/helpers";
import NumberFormat from "react-number-format";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[100],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#b3bec3",
    },
}));


const formatNumber = (value) => {
    return (
        <NumberFormat
            value={value}
            decimalScale={2}
            defaultValue={0}
            thousandSeparator
            displayType={'text'}
            prefix="$"
        />
    );
};


const Dashboard: React.FC = observer(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect( () => {
        dispatch(changeHeaderButton('choose'));
    }, [dispatch]);

    function handleInvestment() {
        navigate(`${NonAuthRoutes.investment}/deposit`);
    }

    return (
        <Grid component="section" container spacing={5}>
            <Grid item xs={12} md={5}>
                <Grid container spacing={6}>
                    {(DataStore.user?.onboarding_completed !== null && DataStore.user.onboarding_completed !== 100) &&
                        <Grid item xs={12}>
                            <Card variant="outlined" className={st.onboarding}>
                                <Typography color="primary" component="h2" variant="h2" className={st.onboarding__title}>
                                    Account Onboarding Steps
                                </Typography>

                                <Typography color="primary" variant="h2" className={st.onboarding__count} mt={2} mb={2}>
                                    <span>{DataStore.user.onboarding_completed === undefined ? 0 : DataStore.user.onboarding_completed}%</span>
                                    <Typography component="span" variant="h4" className={st.onboarding__completed} sx={{ opacity: 0.6 }} ml={2}>
                                        Completed
                                    </Typography>
                                </Typography>

                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} lg>
                                        <BorderLinearProgress variant="determinate" value={DataStore.user?.onboarding_completed ?? 0} />
                                    </Grid>

                                    <Grid item xs={12} lg="auto">
                                        <Button onClick={handleInvestment} variant="contained">Invest More</Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={7} md={7} lg={6}>
                                <TotalInfo title="Total balance"
                                           balance={formatNumber(DataStore.userProfits.usd_amount)}
                                           number={`${neatNumber(DataStore.userProfits.usd_month_profit_percentage, true) ?? ''}`}
                                           toUp={DataStore.userProfits.usd_month_profit_percentage > 0}
                                           toDown={DataStore.userProfits.usd_month_profit_percentage < 0}
                                />
                            </Grid>

                            <Grid item xs={6} sm={5} md={5} lg={4}>
                                <TotalInfo title="total profit"
                                           balance={formatNumber(DataStore.userProfits.usd_total_profit)}
                                           number={`${neatNumber(DataStore.userProfits.usd_total_profit_percentage, true) ?? ''}`}
                                           toUp={DataStore.userProfits.usd_total_profit_percentage > 0}
                                           toDown={DataStore.userProfits.usd_total_profit_percentage < 0}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6} ml="auto">
                <ChartLayout title="Portfolio Assets" icon={<ChartIcon />}>
                    <Box className={st.pieChart}>
                        <PieCharts data={DataStore.userPieChart} />
                    </Box>
                </ChartLayout>
            </Grid>

            <Grid item xs={12}>
                <ChartLayout title="Portfolio Summary" icon={<StatIcon />}>
                    <Box className={st.areaChart}>
                        <AreaCharts data={DataStore.userPortfolioProfits} />
                    </Box>
                </ChartLayout>
            </Grid>
        </Grid>
    );
});

export default Dashboard;
