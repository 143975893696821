import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { NonAuthRoutes } from "../../routes/routes";

// styles
import st from "./ErrorPage.module.scss";

const ErrorPage: React.FC = () => {
    return (
            <section className={st.error_section}>
                <div className={st.error}>
                    <Typography variant="h1" className={st.error__title} mb={4}>
                        Page not found
                    </Typography>
                    <Link to={NonAuthRoutes.home} className={st.error__button}>
                        Go home
                    </Link>
                </div>
            </section>
    );
};

export default ErrorPage;
