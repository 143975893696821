// @ts-nocheck
const initialState = {
    button: "choose",
};

export function header(state = initialState, action) {
    switch (action.type) {
        case 'CHANGE_HEADER':
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
}
