import React from "react";
import Box from "@mui/material/Box";
import st from "./Layout.module.scss";
import { SvgIconProps, Typography } from "@mui/material";
import classNames from "classnames";
import { UserExchanges } from "../UserExchanges";

type props = {
    title: string;
    className?: string;
    icon?: React.ReactNode;
    stopTrading?: boolean;
    exchanges?: boolean;
    children: React.ReactNode;
} & SvgIconProps;

const ChartLayout: React.FC<props> = ({
    icon,
    title,
    className,
    children,
    stopTrading,
    exchanges}) =>
{
    return (
        <Box component="section" className={classNames(st.layout__container, className)}>
            <div className={st.layout__top}>
                <Box className={st.layout__top_chart}>
                    {icon}
                    <Typography variant="h3">{title}</Typography>
                </Box>

                {stopTrading &&
                    <div className={st.layout__top_buttons}>
                        <div className={st.layout__top_stop}>Stop Trading</div>
                        <div>Allocate More</div>
                    </div>
                }

                {exchanges &&
                    <UserExchanges />
                }
            </div>

            {children}
        </Box>
    );
};

export default ChartLayout;
