// @ts-nocheck
import React from "react";
import { observer } from "mobx-react";
import DataStore from "../store/DataStore";
import { Navigate, useLocation } from "react-router-dom";
import { NonAuthRoutes } from "./routes";


const AuthRoute = observer((({ children }: { children: JSX.Element }) => {
    const location = useLocation();

    if (!DataStore.isUserAuthorized || !localStorage.getItem("feasible_token")) {
        return <Navigate to={NonAuthRoutes.signIn} state={{ from: location }} replace />;
    }

    return children;
}));

export default AuthRoute;
