// @ts-nocheck
import {
    Link,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormLabel,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    InputAdornment,
    IconButton,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import st from "./Deposit.module.scss";
import NumberFormat from "react-number-format";
import { IMaskInput } from "react-imask";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableGrid from "../../Table/Table";
import { ReactComponent as TableIcon } from "../../../assets/images/icons/table.svg";
import TableLayout from "../../Table/TableLayout";
import { useDispatch } from "react-redux";
import { changeHeaderButton } from "../../../redux/actions/header";
import { useParams } from "react-router-dom";
import DataStore from "../../../store/DataStore";
import { getData, postData } from "../../../api/api";
import { observer } from "mobx-react";
import { Field, FormikProvider, useFormik } from "formik";
import classNames from "classnames";
import UIStore from "../../../store/UIStore";


interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(amount) => {
                onChange({
                    target: {
                        name: props.name,
                        value: amount.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
});

const TextMaskCard = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCard(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="0000 0000 0000 0000"
            inputRef={ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const TextMaskDate = React.forwardRef<HTMLElement, CustomProps>(function TextMaskDate(props, ref) {
    const { onChange, ...other } = props;
    return (

        <IMaskInput {...other} mask="00/00" inputRef={ref} onAccept={(value: any) => onChange({ target: { name: props.name, value } })} overwrite />
    );
});

const TextMaskCVV = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCVV(props, ref) {
    const { onChange, ...other } = props;

    return <IMaskInput {...other} mask="000" inputRef={ref} onAccept={(value: any) => onChange({ target: { name: props.name, value } })} overwrite />;
});


const Deposit: React.FC = observer(() => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
        alertContentTime,
        setErrorAlertContent,
        setErrorAlertContentTime,
        setSuccessAlertContent,
    } = UIStore;

    React.useEffect( () => {
        dispatch(changeHeaderButton('more'));
    }, [dispatch]);

    React.useEffect(() => {
        let isMounted = true;
        getData(`funds/${id}/transactions`)
            .then(({ data }) => {
                if (isMounted) {
                    DataStore.updateCurrentFundTransactions(data);
                }
            })
            .catch(error => console.log({error}));

        getData(`funds/${id}/history`)
            .then(({ data }) => {
                console.log("funds assets", data);
                if (isMounted) {
                    DataStore.updateCurrentFundHistory(data);
                }
            })
            .catch(error => console.log({error}));

        return () => { isMounted = false };
    }, [id]);

    const [value, setValue] = React.useState("1");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        Object.keys(form.touched).forEach(key => delete form.touched[key]);
        setValue(newValue);
    };

    const handleInputAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue('amount', event.target.value);
    };

    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(form.values.wallet_address);
    };


    const validate = (values: any) => {
        const errors: any = {};

        if (!values.amount) {
            errors.amount = "Required";
        } else if (Number(values.amount) <= 0) {
            errors.amount = "Value must be greater than zero";
        }

        if (value === '1') {
            if (!values.currency) {
                errors.currency = "Required";
            }

            if (!values.card_number) {
                errors.card_number = "Required";
            }

            if (!values.expiration_date) {
                errors.expiration_date = "Required";
            }

            if (!values.cvv) {
                errors.cvv = "Required";
            }

            if (!values.card_holder_name) {
                errors.card_holder_name = "Required";
            }
        } else {
            if (!values.coin) {
                errors.coin = "Required";
            }

            if (!values.network) {
                errors.network = "Required";
            }

            if (!values.wallet_address) {
                errors.wallet_address = "Required";
            }
        }

        return errors;
    }

    let initialValues = {
        amount: "",
        currency: "",
        card_number: "",
        card_holder_name: "",
        expiration_date: "",
        cvv: "",
        coin: "",
        network: "",
        wallet_address: "",
    };

    const form = useFormik({
        validate: validate,
        initialValues: initialValues,
        onSubmit: (values) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);
            postData(`funds/${id}/deposit_${value === '1' ? 'fiat' : 'crypto'}`,
                (value === '1'?
                        { amount: values.amount, currency: values.currency, card_number: values.card_number, card_holder_name: values.card_holder_name, expiration_date: values.expiration_date, cvv: values.cvv, }
                        :
                        { amount: values.amount, coin: values.coin, network: values.network, wallet_address: values.wallet_address, }
                ), 'post')
                .then(({ data }) => {
                    console.log('data', data);
                    setSuccessAlertContent("Successfully deposited");

                })
                .catch((errorMessage) => {
                        setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
                    }
                );
        },
    });

    return (
        <Box component="section" className={st.deposit__container}>
            <Grid container spacing={{ xs: 4, lg: 2}}>
                <Grid item xs={12} md={6}>
                    <Typography color="primary" component="h1" variant="h2" className={st.deposit__title}>
                        Deposit Funds
                    </Typography>

                    <Grid item xs={12} md={10} lg={8} mt={3}>
                        <FormikProvider value={form}>
                            <Box component="form" onSubmit={form.handleSubmit}>
                                <Grid item xs={12}>
                                    <Field
                                        name="amount"
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type="text"
                                                fullWidth
                                                label="Amount"
                                                onChange={handleInputAmount}
                                                id="input-amount"
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom as any,
                                                }}
                                                variant="standard"
                                                error={form.touched.amount && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.amount && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                            <TabList onChange={handleTabChange} aria-label="Tabs for type">
                                                <Tab label="FIAT" value="1" />
                                                <Tab label="Crypto" value="2" />
                                            </TabList>
                                        </Box>

                                        <TabPanel value="1" sx={{ p: 0 }}>
                                            <FormControl sx={{ mt: 3 }}>
                                                <FormLabel id="type-currency">Currency</FormLabel>
                                                <RadioGroup
                                                    row aria-labelledby="type-currency"
                                                    value={form.values.currency}
                                                    name="currency"
                                                    className={classNames(form.touched.currency && !form.isValid && ("currency" in form.errors) ? 'error' : '')}
                                                    onChange={(e) => {
                                                        form.handleChange(e);
                                                    }}
                                                >
                                                    <FormControlLabel value="EUR" control={<Radio />} label="EUR" />
                                                    <FormControlLabel value="USD" control={<Radio />} label="USD" />
                                                    <FormControlLabel value="GBP" control={<Radio />} label="GBP" />
                                                </RadioGroup>
                                            </FormControl>

                                            <Grid container spacing={1} mt={2}>
                                                <Grid item xs={8}>
                                                    <Field
                                                        name="card_number"
                                                        children={(fieldProps: any) => (
                                                            <TextField
                                                                {...fieldProps.field}
                                                                type="text"
                                                                fullWidth
                                                                label="Card number"
                                                                InputProps={{
                                                                    inputComponent: TextMaskCard as any,
                                                                }}
                                                                variant="standard"
                                                                error={form.touched.card_number && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                                helperText={form.touched.card_number && !form.isValid && fieldProps.meta.error}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <Field
                                                        name="expiration_date"
                                                        children={(fieldProps: any) => (
                                                            <TextField
                                                                {...fieldProps.field}
                                                                type="text"
                                                                fullWidth
                                                                label="EXP. Date"
                                                                InputProps={{
                                                                    inputComponent: TextMaskDate as any,
                                                                }}
                                                                variant="standard"
                                                                error={form.touched.expiration_date && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                                helperText={form.touched.expiration_date && !form.isValid && fieldProps.meta.error}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <Field
                                                        name="cvv"
                                                        children={(fieldProps: any) => (
                                                            <TextField
                                                                {...fieldProps.field}
                                                                type="text"
                                                                fullWidth
                                                                label="CVV"
                                                                InputProps={{
                                                                    inputComponent: TextMaskCVV as any,
                                                                }}
                                                                variant="standard"
                                                                error={form.touched.cvv && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                                helperText={form.touched.cvv && !form.isValid && fieldProps.meta.error}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Field
                                                name="card_holder_name"
                                                children={(fieldProps: any) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        type="text"
                                                        fullWidth
                                                        label="Cardholder name"
                                                        variant="standard"
                                                        error={form.touched.card_holder_name && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={form.touched.card_holder_name && !form.isValid && fieldProps.meta.error}
                                                    />
                                                )}
                                            />
                                        </TabPanel>

                                        <TabPanel value="2" sx={{ p: 0 }}>
                                            <FormControl sx={{ mt: 3 }}>
                                                <FormLabel id="type-coin">Coin</FormLabel>
                                                <RadioGroup
                                                    row aria-labelledby="type-coin"
                                                    name="coin"
                                                    value={form.values.coin}
                                                    className={classNames(form.touched.coin && !form.isValid && ("coin" in form.errors) ? 'error' : '')}
                                                    onChange={(e) => {
                                                        form.handleChange(e);
                                                    }}
                                                >
                                                    <FormControlLabel value="USDT" control={<Radio />} label="USDT" />
                                                    <FormControlLabel value="BUSD" control={<Radio />} label="BUSD" />
                                                    <FormControlLabel value="USDC" control={<Radio />} label="USDC" />
                                                </RadioGroup>
                                            </FormControl>

                                            <FormControl sx={{ mt: 3 }}>
                                                <FormLabel id="type-network">Network</FormLabel>
                                                <RadioGroup
                                                    row aria-labelledby="type-network"
                                                    name="network"
                                                    value={form.values.network}
                                                    className={classNames(form.touched.network && !form.isValid && ("network" in form.errors) ? 'error' : '')}
                                                    onChange={(e) => {
                                                        form.handleChange(e);
                                                    }}
                                                >
                                                    <FormControlLabel value="ERC20" control={<Radio />} label="ERC20" />
                                                    <FormControlLabel value="BSC" control={<Radio />} label="BSC" />
                                                    <FormControlLabel value="TRC20" control={<Radio />} label="TRC20" />
                                                    <FormControlLabel value="Solana" control={<Radio />} label="Solana" />
                                                    <FormControlLabel value="Polygon" control={<Radio />} label="Polygon" />
                                                </RadioGroup>
                                            </FormControl>

                                            <Field
                                                name="wallet_address"
                                                children={(fieldProps: any) => (
                                                    <TextField
                                                        {...fieldProps.field}
                                                        type="text"
                                                        fullWidth
                                                        label="Wallet address"
                                                        id="input-amount"
                                                        InputProps={{
                                                            endAdornment:
                                                                <InputAdornment position="end">
                                                                    <IconButton aria-label="wallet address copy" onClick={handleCopyClick} edge="end">
                                                                        <ContentCopyIcon />
                                                                    </IconButton>
                                                                </InputAdornment>,
                                                        }}
                                                        variant="standard"
                                                        error={form.touched.wallet_address && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                        helperText={form.touched.wallet_address && !form.isValid && fieldProps.meta.error}
                                                    />
                                                )}
                                            />
                                        </TabPanel>
                                    </TabContext>
                                </Grid>

                                <Button type="submit" size="large" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                    Deposit funds
                                </Button>

                                <Grid container>
                                    <Grid item textAlign="center" xs>
                                        By depositing funds into our investment fund, you agree to our{" "}
                                        <Link href="#" underline="always" color="primary">
                                            Risk & Disclosure Agreement
                                        </Link>
                                        .
                                    </Grid>
                                </Grid>
                            </Box>
                            {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre>*/}
                        </FormikProvider>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography color="primary" component="h2" variant="h2" className={st.deposit__title}>
                        Transaction status:
                    </Typography>

                    <Grid item xs={12} mt={3}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th">
                                            <Typography sx={{ opacity: 0.6 }}>Date</Typography>
                                        </TableCell>
                                        <TableCell component="th">
                                            <Typography sx={{ opacity: 0.6 }}>Status</Typography>
                                        </TableCell>
                                        <TableCell component="th">
                                            <Typography sx={{ opacity: 0.6 }}>Amount</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {DataStore.currentFundTransactions.map((item, index) => {
                                        return (
                                            <TableRow key={'trans' + index}>
                                                <TableCell>
                                                    {new Date(item.date).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', })}
                                                </TableCell>
                                                <TableCell className={item.amount > 0 ? 'green' : (item.amount < 0 ? 'red' : '')}>{item.status}</TableCell>
                                                <TableCell className={item.amount > 0 ? 'green' : (item.amount < 0 ? 'red' : '')}>${item.amount}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                <Grid item xs={12} mt={5}>
                    <TableLayout title="History" icon={<TableIcon />}>
                        <TableGrid data={DataStore.currentFundHistory} />
                    </TableLayout>
                </Grid>
            </Grid>
        </Box>
    );
});

export default Deposit;
