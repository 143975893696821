import * as React from "react";
import { Field, FormikProvider, useFormik, ErrorMessage } from 'formik';
import validator from "validator";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { NonAuthRoutes } from "../../../routes/routes";

import st from "../Auth.module.scss";
import logo from "../../../assets/images/logo.png";
import { postData } from "../../../api/api";
import UIStore from "../../../store/UIStore";
import DataStore from "../../../store/DataStore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { randomIntFromInterval } from '../../../helpers/helpers'
import key from "../../../assets/images/key.jpeg";
import password from "../../../assets/images/password.jpeg";


const imageKey = randomIntFromInterval(1, 2);
const SignUp: React.FC = () => {
    const navigate = useNavigate();
    const routLocation = useLocation();
    const query = new URLSearchParams(routLocation.search);
    const { alertContentTime, setErrorAlertContent, setErrorAlertContentTime} = UIStore;

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.first_name.trim()) {
            errors.first_name = "Required";
        }

        if (!values.last_name.trim()) {
            errors.last_name = "Required";
        }

        if (!values.email) {
            errors.email = "Required";
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = "Invalid email";
        }

        if (!values.password) {
            errors.password = "Required";
        }

        if (values.password && values.password.length < 6) {
            errors.password = "At least 6 symbols";
        }

        if (values.password && (values.password.length > 5) && values.confirm_password && (values.confirm_password !== values.password)) {
            errors.password = "Password does not match";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Required";
        }

        if (!values.terms) {
            errors.terms = "Required";
        }

        if (values.confirm_password && values.confirm_password !== values.password) {
            errors.confirm_password = "Password does not match";
        }

        return errors;
    }
    const form = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            confirm_password: '',
            referral_code: '',
            terms: false,
        },
        validate: validate,
        onSubmit: (values, { setErrors }) => {
            console.log(values, setErrors);
            setErrorAlertContent('', true);
            postData('sign_up', values, 'post')
                .then(({ data }) => {
                    console.log('data', data);
                    if (data.user?.id && data.jwt) {
                        DataStore.updateAuthorize(true);
                        DataStore.updateNeedDataUpdate(true);
                        DataStore.updateUser(data.user);
                        localStorage.setItem("feasible_token", data.jwt);
                        navigate("/", { replace: true });
                    } else {
                        console.log('data');
                    }
                })
                .catch((errorMessage) => {
                        console.log(errorMessage)
                        setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
                    }
                );
        },
    });

    React.useEffect(() => {
        if (query.get('referralCode') !== null) {
            form.setFieldValue('referral_code', query.get('referralCode'));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid item xs={12} sm={8} md={5}
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        mt: { xs: 2, md: 5 },
                        mx: { xs: 3, md: 10, lg: 20 },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div className={st.logo}>
                        <Link to={NonAuthRoutes.home}>
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <Typography component="h1" variant="h3" mb={2}>
                        Get started for free
                    </Typography>
                    <Typography component="p" variant="subtitle1" mb={2}>
                        Create an account for yourself to check your statistics
                    </Typography>
                    <FormikProvider value={form}>
                        <Box component="form" noValidate onSubmit={form.handleSubmit} sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="first_name"
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type="text"
                                                fullWidth
                                                label="First Name"
                                                error={form.touched.first_name && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.first_name && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="last_name"
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type="text"
                                                fullWidth
                                                label="Last Name"
                                                error={form.touched.last_name && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.last_name && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name="email"
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type="text"
                                                fullWidth
                                                label="Email Address"
                                                error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name="password"
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type="password"
                                                fullWidth
                                                label="Password"
                                                error={form.touched.password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.password && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name="confirm_password"
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type="password"
                                                fullWidth
                                                label="Confirm password"
                                                error={form.touched.confirm_password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.confirm_password && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name="referral_code"
                                        children={(fieldProps: any) => (
                                            <TextField
                                                {...fieldProps.field}
                                                type="text"
                                                fullWidth
                                                label="Referral code"
                                                error={form.touched.referral_code && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                helperText={form.touched.referral_code && !form.isValid && fieldProps.meta.error}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                                        label="I want to receive inspiration, marketing promotions and updates via email."
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        type="checkbox"
                                        name="terms"
                                        as={FormControlLabel}
                                        control={<Checkbox className={form.errors.terms ? st.error__checkbox : ""} checked={form.values.terms} value="terms" color="primary" />}
                                        onChange={form.handleChange}
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        label={(<p>I accept <a className={st.terms__link} href="#">Terms</a> and <a className={st.terms__link} href="#">Conditions</a></p>)}
                                    />
                                    <ErrorMessage render={msg => <Typography color="error">{msg}</Typography>} name="terms" />
                                </Grid>
                            </Grid>
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                Sign Up
                            </Button>
                            <Grid container>
                                <Grid item textAlign="center" xs>
                                    <Typography variant="body2">
                                        Do you have an account?{" "}
                                        <Link to={NonAuthRoutes.signIn} className={st.link}>
                                            {"Sign In"}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
{/*                        <pre style={{color: "saddlebrown", }}>{// @ts-ignore
                            JSON.stringify(form.values, 0, 2)}</pre>*/}
                    </FormikProvider>
                </Box>
            </Grid>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${imageKey === 1 ? key : password })`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
        </Grid>
    );
};

export default SignUp;
