import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#b3bec3",
            dark: "#a4a4a4",
        },
        secondary: {
            main: "#23292c",
        },
        error: {
            main: "#fa3c41",
        },
        success: {
            main: "#43d854",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 1024,
            lg: 1440,
            xl: 1920,
        },
    },
});

const primaryMuiTheme = createTheme({
    shape: {
        borderRadius: 0,
    },
    palette: {
        mode: "dark",
        text: {
            primary: defaultTheme.palette.primary.main,
            disabled: defaultTheme.palette.primary.dark,
        },
        background: {
            default: "transparent",
            paper: "transparent",
        },
        primary: {
            main: defaultTheme.palette.primary.main,
            dark: defaultTheme.palette.primary.dark,
        },
        secondary: {
            main: defaultTheme.palette.secondary.main,
        },
        error: {
            main: defaultTheme.palette.error.main,
        },
        success: {
            main: defaultTheme.palette.success.main,
        },
    },
    typography: {
        fontFamily: "FLURO",

        h1: {
            fontWeight: "700",
            fontSize: "40px",
            lineHeight: "36px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "24px",
                lineHeight: "20px",
            },
        },
        h2: {
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: "28px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "20px",
                lineHeight: "16px",
            },
        },
        h3: {
            fontWeight: "700",
            fontSize: "28px",
            lineHeight: "24px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "22px",
                lineHeight: "18px",
            },
        },
        h4: {
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "16px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "14px",
            },
        },
        h5: {
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "14px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "14px",
            },
        },
        h6: {
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "14px",
            letterSpacing: "-0.02em",
            [defaultTheme.breakpoints.down("sm")]: {
                fontSize: "14px",
                lineHeight: "14px",
            },
        },
        button: {
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "18px",
            letterSpacing: "-0.02em",
            textTransform: "none",
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "circle", color: "primary" },
                    style: {
                        textTransform: "none",
                        backgroundColor: defaultTheme.palette.primary.main,
                        border: `1px solid ${defaultTheme.palette.primary.main}`,
                        ":hover": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            color: defaultTheme.palette.primary.main,
                        },
                    },
                },
                {
                    props: { variant: "circle", color: "secondary" },
                    style: {
                        textTransform: "none",
                        backgroundColor: defaultTheme.palette.secondary.main,
                        border: `1px solid ${defaultTheme.palette.secondary.main}`,
                        ":hover": {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            color: defaultTheme.palette.secondary.main,
                        },
                    },
                },

                // types
                {
                    props: { variant: "circle", size: "large" },
                    style: {
                        padding: "0px !important",
                        width: "80px !important",
                        height: "80px !important",
                    },
                },
                {
                    props: { variant: "circle", size: "medium" },
                    style: {
                        padding: "0 !important",
                        width: "64px !important",
                        height: "64px !important",
                    },
                },
                {
                    props: { variant: "circle", size: "small" },
                    style: {
                        padding: "0 !important",
                        width: "32px !important",
                        height: "32px !important",
                    },
                },

                // sizes
                {
                    props: { size: "large" },
                    style: {
                        padding: "20px 40px",
                        height: "56px",
                        fontSize: "20px",
                    },
                },
                {
                    props: { size: "medium" },
                    style: {
                        padding: "15px 32px",
                        height: "48px",
                        fontSize: "18px",
                    },
                },
                {
                    props: { size: "medium" },
                    style: {
                        padding: "15px 32px",
                        height: "48px",
                    },
                },
                {
                    props: { size: "small" },
                    style: {
                        padding: "10px 28px",
                        height: "40px",
                        fontSize: "14px",
                    },
                },
            ],
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: defaultTheme.palette.primary.main
                }
            }
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 1024,
            lg: 1440,
            xl: 1920,
        },
    },
});

export default primaryMuiTheme;
export { defaultTheme };
