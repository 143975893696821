import * as React from "react";
import { Route, Routes } from "react-router-dom";
import "./settings/axios";
import "./assets/styles/global/index.scss";
import Auth from "./components/Auth/Auth";
import SignIn from "./components/Auth/SignIn/SignIn";
import SignUp from "./components/Auth/SignUp/SignUp";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Dashboard from "./components/Dashboard/Dashboard";
import Layout from "./components/Layout/Layout";
import { NonAuthRoutes } from "./routes/routes";
import NetWorth from "./components/NetWorth/NetWorth";
import Investments from "./components/Investment/Investments";
import InvestmentsLayout from "./components/Investment/Layout";
import Investment from "./components/Investment/Investment";
import Deposit from "./components/Investment/Deposit/Deposit";
import Settings from "./components/Settings/Settings";
import Exchanges from "./components/Exchanges/Exchanges";
import AuthRoute from "./routes/AuthRoute";
import { observer } from "mobx-react";
import ForgotPassword from "components/Auth/ForgotPassword/ForgotPassword";
import NewPassword from "components/Auth/NewPassword/NewPassword";


const App: React.FC = observer(() => {
    return (
      <Routes>
        <Route element={<AuthRoute><Layout /></AuthRoute>}>
            <Route index element={<Dashboard />} />
            <Route path={NonAuthRoutes.netWorth} element={<NetWorth />} />
            <Route path={NonAuthRoutes.investment} element={<InvestmentsLayout />}>
                <Route index element={<Investments />} />
                <Route path=":id" element={<Investment />} />
                <Route path="deposit/:id" element={<Deposit />} />
            </Route>
            <Route path={NonAuthRoutes.settings} element={<Settings />} />

            <Route path={NonAuthRoutes.exchanges} element={<Exchanges />} />
        </Route>

        <Route element={<Auth />}>
            <Route path={NonAuthRoutes.signIn} element={<SignIn />} />
            <Route path={NonAuthRoutes.signUp} element={<SignUp />} />
            <Route path={NonAuthRoutes.forgotPassword} element={<ForgotPassword />} />
            <Route path={NonAuthRoutes.newPassword} element={<NewPassword />} />

        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
});

export default App;
