// @ts-nocheck
import axios from "axios";
import { NonAuthRoutes } from "../routes/routes";

function request(method, url, ld, sendData, setPagination, options) {
    // console.log('api url', url);

    let headers = Object.assign({
        // accept: "**",
        // accept: "application/json",
        // jwt: localStorage.getItem('feasible_token'),
        // Authorization: "Bearer " + localStorage.getItem('token'),
    }, options);

    if (ld) {
        headers.accept = 'application/ld+json';
    } else if (method === 'delete') {
        headers.accept = '*/*';
    } else if (method === 'patch') {
        headers['content-type'] = 'application/merge-patch+json';
    } else if (method === 'post' || method === 'put') {
        headers['content-type'] = 'application/json';
    }

    if (localStorage.getItem("feasible_token") && localStorage.getItem("feasible_token") !== 'undefined') {
        headers.jwt = localStorage.getItem('feasible_token');
    }

    let params = {
        method: method,
        // url: axios.defaults.baseURL + url,
        // url: 'https://virtserver.swaggerhub.com/sgerodes/FesibleX-BE-api-v1/1.0.0/' + url,
        url: 'https://feasiblex-backend-f956fe6367b0.herokuapp.com/api/v1/' + url,
        // url: `${axios.defaults.baseURL}/${url}`,
        headers: headers,
    };

    if (sendData) {
        params.data = sendData;
    }
    // console.log('params', params);

    return axios(params)
        .then(function(response) {
            // console.log('Axios success response', response);
            // console.log('response.headers', response.headers);
            if (response.data["@type"] === "hydra:Collection") {
                response.totalItems = response.data["hydra:totalItems"];
                if (setPagination && response.data["hydra:view"]) {
                    setPagination(parseInt(response.data["hydra:view"]["hydra:last"].match(/\d+$/)[0], 10));
                    // setPagination(response.data['hydra:view']);
                }
                response.data = response.data["hydra:member"];
            }
            return response;
        })
        .catch(function(error) {
            console.log("error", error);
            let errorMessage = "An error occurred during the request. Try to repeat the request.";

            if (error.response && (
                    error.response.data?.detail ||
                    error.response.status === 401 ||
                    error.response.data?.violations ||
                    error.response.data?.display_message ||
                    error.response.data?.message ||
                    error.response.statusText))
            {
                // Request made and server responded
                if (error.response.status === 401) {
                    if (url === "sign_in") {
                        errorMessage = "Credentials are missing or incorrect.";
                    } else {
                        window.location = `${NonAuthRoutes.signIn}`;
                    }
                } else if (error.response.data.violations) {
                    console.log("error.response.data.violations", error.response.data.violations);
                    errorMessage = error.response.data.violations;
                } else if (error.response.data.detail) {
                    console.log("error.response.data", error.response.data);
                    errorMessage = error.response.data.detail;
                } else if (error.response.data.display_message) {
                    errorMessage = error.response.data.display_message;
                } else if (error.response.data.message) {
                    // errorMessage = 'An error has occurred';
                    errorMessage = error.response.data.message;
                } else if (error.response.statusText) {
                    console.log("statusText", error.response.statusText);
                    errorMessage = error.response.statusText;
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
            }

            return Promise.reject(errorMessage);
        });
}

export const patchData = (url, sendData) => {
    return request('patch', url, false, sendData);
}

export const deleteData = (url) => {
    return request('delete', url);
}

export const getData = (url: string, ld = false, setPagination = false) => {
    return request('get', url, ld, [], setPagination);
}

export const postData = (url, sendData, method, ld = false) => {
    return request(method, url, ld, sendData);
}
