// @ts-nocheck
import { useParams } from "react-router-dom";
import TotalInfo from "../TotalInfo/TotalInfo";
import { Box, Grid } from "@mui/material";
import TableLayout from "../Table/TableLayout";
import TableGrid from "../Table/Table";
import classNames from "classnames";
import ChartLayout from "../Chart/Layout";

// styles
import st from "./Investment.module.scss";
import { ReactComponent as ArrowUpIcon } from "../../assets/images/icons/arrow-to-up.svg";
import { ReactComponent as DollarIcon } from "../../assets/images/icons/dollar.svg";
import { ReactComponent as TableIcon } from "../../assets/images/icons/table.svg";
import React from "react";
import BarCharts from "../Chart/Bar";
import { getData } from "../../api/api";
import DataStore from "../../store/DataStore";
import { neatNumber } from "../../helpers/helpers";
import { observer } from "mobx-react";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/icons/arrow-to-down.svg";
import NumberFormat from "react-number-format";

const formatNumber = (value) => {
    return (
        <NumberFormat
            value={value}
            decimalScale={2}
            defaultValue={0}
            thousandSeparator
            displayType={'text'}
            prefix="$"
        />
    );
};


const Investment: React.FC = observer(() => {
    const { id } = useParams();
    const { currentFund: item } = DataStore;

    React.useEffect(() => {
        let isMounted = true;
        // @ts-ignore
        const findingInvestment = DataStore.funds.find(item => item.id === Number(id));

        if (findingInvestment !== undefined) {
            if (isMounted) {
                DataStore.updateCurrentFund(findingInvestment);
            }
        } else {
            getData(`funds/${id}`)
                .then(({ data }) => {
                    if (isMounted) {
                        DataStore.updateCurrentFund(data);
                    }
                })
                .catch(error => console.log({error}));
        }

        getData(`funds/${id}/performance`)
            .then(({ data }) => {
                if (isMounted) {
                    DataStore.updateCurrentFundPerformance(data?.length ? data.reverse() : data);
                }
            })
            .catch(error => console.log({error}));

        getData(`funds/${id}/assets`)
            .then(({ data }) => {
                console.log("funds assets", data);
                if (isMounted) {
                    DataStore.updateCurrentFundAssets(data);
                }
            })
            .catch(error => console.log({error}));


        return () => { isMounted = false };
    }, [id]);

    return (
        <Grid component="section" container spacing={6}>
            <Grid item xs={12} sm={6} lg={4}>
                <div className={st.total__title}>{item.name}</div>
                <div className={st.total__text}>{new Date(item.starting_date).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', })} 🔥</div>
                <div className={st.total__text}>Members: {item.members}</div>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
                <Grid container>
                    <Grid item xs={12} className={st.total__item} mb={3}>
                        <div className={st.total__big}>{formatNumber(item.total_profit)}</div>
                        <div className={st.total__small}>Total</div>
                    </Grid>

                    <Grid item xs={12} className={st.total__item} mb={2}>
                        <div className={st.total__big}>{item.formatNumber}</div>
                        <div className={st.total__small}>Last 30 Days</div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={classNames(st.total__number, st.total__number_up)}>
                            {item.montly_change_percentage &&
                                (item.montly_change_percentage > 0 ?
                                    <ArrowUpIcon className={st.total__arrow} />
                                    :
                                    <ArrowDownIcon className={st.total__arrow} />
                                )
                            }

                            <span>{neatNumber(item.montly_change_percentage, true)}</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
                <TotalInfo title="Weekly"
                           balance={formatNumber(item.daily_change_net)}
                           number={item.daily_change_percentage?.toFixed(2) ?? ''}
                           toUp={item.daily_change_percentage > 0}
                           toDown={item.daily_change_percentage < 0}
                />
            </Grid>

            <Grid item xs={12} sm={6} lg={2}>
                <TotalInfo title="Monthly"
                           balance={formatNumber(item.montly_change_net)}
                           number={item.montly_change_percentage?.toFixed(2) ?? ''}
                           toUp={item.montly_change_percentage > 0}
                           toDown={item.montly_change_percentage < 0}
                />
            </Grid>

            <Grid item xs={12} pt={10}>
                <ChartLayout title="Performance" icon={<DollarIcon />} exchanges>
                    <Box className={st.barChart}>
                        <BarCharts data={DataStore.currentFundPerformance} />
                    </Box>
                </ChartLayout>
            </Grid>

            <Grid item xs={12} pt={10}>
                <TableLayout title="Assets" icon={<TableIcon />}>
                    <TableGrid data={DataStore.currentFundAssets} />
                </TableLayout>
            </Grid>
        </Grid>
    );
});

export default Investment;
