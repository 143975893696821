// @ts-nocheck
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from "recharts";
import st from "./Layout.module.scss";
import React from "react";
import NumberFormat from "react-number-format";


const AreaCharts = ({ data }) => {
    const [dataAreaCharts, setAreaCharts] = React.useState([]);

    const formatNumber = (value) => {
        return (
            <NumberFormat
                value={value}
                // decimalScale={2}
                defaultValue={0}
                thousandSeparator
                displayType={'text'}
                prefix="$"
            />
        );
    };

    React.useEffect(() => {
        if (data.length) {
            setAreaCharts(data.map((item, index) => {
                return {name: new Date(2020, item.month - 1, 10).toLocaleString('En', { month: 'short' }), value: item.return_value,}
            }));
        }
    }, [data]);

    const CustomTooltip = (props: any) => {
        if (props.active && props.payload && props.payload.length) {
            return (
                <div className={st.customTooltip}>
                    {props.payload[0].payload.up ? (
                        <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.1662 6.99906V23.3751H13.5829V6.99906L20.9167 14.3329L23.3323 11.9174L11.8745 0.459564L0.416748 11.9174L2.83233 14.3329L10.1662 6.99906Z"
                                fill="#D6E7ED"
                            />
                        </svg>
                    ) : (
                        <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.166 17.0015V0.625481H13.5827V17.0015L20.9165 9.66768L23.3321 12.0832L11.8743 23.541L0.416504 12.0832L2.83209 9.66768L10.166 17.0015Z"
                                fill="#D6E7ED"
                            />
                        </svg>
                    )}
                    {formatNumber(props.payload[0].value)}
                </div>
            );
        }

        return null;
    };

    const customLegend = (props: any) => {
        const { payload } = props;

        return (
            <ul>
                {payload.map((entry: any, index: number) => (
                    <li key={`item-${index}`} className={st.legend}>
                        <svg width="8" height="8" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="7.29162" cy="7.33318" r="6.83333" fill={entry.color} />
                        </svg>
                        <span style={{ color: entry.color }}>{entry.value}</span>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                data={dataAreaCharts}
                margin={{
                    top: 10,
                    right: 20,
                    left: -10,
                    bottom: 0,
                }}
            >
                <CartesianGrid vertical={false} stroke="#B3BEC3" strokeOpacity="0.2"  />
                <XAxis dataKey="name" />
                <YAxis axisLine={false} />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                    align="left"
                    verticalAlign="top"
                    iconType="cirlce"
                    content={customLegend}
                />
                <Area name="Return value" dataKey="value" stroke="#fff" fill="#d6e7ed" fillOpacity="0.2" />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default AreaCharts;
