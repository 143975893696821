// @ts-nocheck
import { Box, Button, Grid, TextField, ToggleButton, Typography } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// styles
import st from "./exchanges.module.scss";
import stylePopup from "../Popup/popup.module.scss";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import UIStore from "../../store/UIStore";
import { Field, FormikProvider, useFormik } from "formik";
import { deleteData, getData, postData } from "../../api/api";
import DataStore from "../../store/DataStore";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Popup } from "../Popup";
import { NonAuthRoutes } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckedIcon } from "../../assets/images/icons/checked.svg";


const Investment: React.FC = observer(() => {
    const navigate = useNavigate();
    const {
        alertContentTime,
        setErrorAlertContent,
        setErrorAlertContentTime,
        setSuccessAlertContent,
        openPopup,
        closePopup,
    } = UIStore;
    const [exchange, setExchange] = React.useState<string | null>(DataStore.staticExchanges[0].market_code);
    const [activeConnectedExchange, setActiveConnectedExchange] = React.useState<Object | undefined>(undefined);
    const [successDisconnect, setSuccessDisconnect] = React.useState(false);

    const handleExchange = (event: React.MouseEvent<HTMLElement>, newExchange: string | null) => {
        if (newExchange) {
            setExchange(newExchange);

            if (form?.values && 'market_code' in form.values) {
               form.setFieldValue('market_code', newExchange);
           }
        }
    };

    const updateUserExchanges = () => {
        getData(`users/${DataStore.user.id}/exchanges`)
            .then(({data}) => {
                DataStore.updateUserExchanges(data, true);
            })
            .catch(error => {
                console.log({error});
            });
    }

    const handleSubmit = () => {
      if (activeConnectedExchange?.id) {
          deleteData(`users/${DataStore.user.id}/exchanges/${activeConnectedExchange.id}`)
              .then(response => {
                  setSuccessDisconnect(true);
                  updateUserExchanges();
              })
              .catch((error) => {
                  console.log('error', error);
                  setErrorAlertContentTime(JSON.stringify(error), alertContentTime);
              });
      }
    }

    React.useEffect(() => {
        let isMounted = true;

        const currentConnectedExchange = DataStore.userExchanges.find(object => object.market_code === exchange);
        if (currentConnectedExchange !== undefined && isMounted) {
            setActiveConnectedExchange(currentConnectedExchange);
        }

        return () => { isMounted = false };
    }, [exchange]);

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.name) {
            errors.name = "Required";
        }

        if (!values.api_key) {
            errors.api_key = "Required";
        }

        if (exchange === 'gdax' && !values.passphrase) {
            errors.passphrase = "Required";
        }

        if (!values.secret_key) {
            errors.secret_key = "Required";
        }

        return errors;
    }

    const form = useFormik({
        initialValues: {
            market_code: DataStore.staticExchanges[0].market_code,
            name: "",
            api_key: "",
            passphrase: "",
            secret_key: "",
        },
        validate: validate,
        onSubmit: (values, { setErrors }) => {
            setSuccessAlertContent('', true);
            setErrorAlertContent('', true);
            postData(`users/${DataStore.user.id}/exchanges`, values, 'post')
                .then(() => {
                    updateUserExchanges();
                    setSuccessAlertContent("Exchange Successfully Integrated");

                    setTimeout(() => {
                        setSuccessAlertContent('', true);
                        navigate(`${NonAuthRoutes.investment}`);
                    }, 2000);
                })
                .catch((errorMessage) => {
                    setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
                });
        },
    });

    return (
        <Box component="section" className={st.deposit__container}>
            <Grid container spacing={{ xs: 2, md: 4, lg: 6 }}>
                <Grid item xs={12} md={6}>
                    <Typography color="primary" component="h1" variant="h2" className={st.deposit__title}>
                        Connect Exchange
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <FormikProvider value={form}>
                        <Box component="form" onSubmit={form.handleSubmit}>
                            <Grid container spacing={{ xs: 2, md: 4, lg: 6 }}>
                                <Grid item xs={12}>
                                    <ToggleButtonGroup value={exchange} exclusive onChange={handleExchange} className={st.radio_container}>
                                        {DataStore.staticExchanges.map((item, index) => (
                                            <ToggleButton key={index} value={item.market_code} aria-label="Exchanged"
                                                          className={classNames(st.radio, (DataStore.userExchanges.find(object => object.market_code === item.market_code) ? st.radio_active : ''))}
                                            >
                                                <div className={st.radio__badge}>
                                                    <CheckIcon color="action" />
                                                </div>
                                                <div className={st.radio__icon}>
                                                    <img src={item.icon} alt={item.id} />
                                                </div>
                                                <div className={st.radio__title}>{item.title}</div>
                                                <div className={st.radio__text}>{item.text}</div>
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                </Grid>

                                {DataStore.userExchanges.map((item, index) => (
                                    <Grid item xs={12} key={'userExchanges' + index} className={st.connected_exchange}>
                                        <div className={st.connected_exchange__info}>
                                            <div>
                                                <div>Exchange name</div>
                                                <div>{DataStore.staticExchanges.find((value) => value.market_code === item.market_code).title}</div>
                                            </div>

                                            <div>
                                                <div>Connection date</div>
                                                <div>
                                                    {new Date(item?.connection_date).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', })}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={st.connected_exchange__buttons}>
                                            <div className={st.connected_exchange__disconnect}
                                                 onClick={(e) => {
                                                     setActiveConnectedExchange(DataStore.userExchanges.find(object => object.market_code === item.market_code));
                                                     openPopup();
                                                     setSuccessDisconnect(false);
                                                 }}
                                            >
                                                Disconnect
                                            </div>

                                            <div className={st.connected_exchange__connected}>
                                                <CheckedIcon />
                                                Exchange Integrated
                                            </div>
                                        </div>
                                    </Grid>
                                ))}

                                {(DataStore.userExchanges.find(object => object.market_code === exchange) === undefined) && form.values.market_code &&
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        name="name"
                                                        children={(fieldProps: any) => (
                                                            <TextField
                                                                {...fieldProps.field}
                                                                type="text"
                                                                fullWidth
                                                                label="ACCOUNT NAME"
                                                                error={form.touched.name && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                                helperText={form.touched.name && !form.isValid && fieldProps.meta.error}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                {exchange === 'gdax' &&
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                            name="passphrase"
                                                            children={(fieldProps: any) => (
                                                                <TextField
                                                                    {...fieldProps.field}
                                                                    type="text"
                                                                    fullWidth
                                                                    label="PASSPHRASE"
                                                                    error={form.touched.passphrase && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                                    helperText={form.touched.passphrase && !form.isValid && fieldProps.meta.error}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                }

                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        name="api_key"
                                                        children={(fieldProps: any) => (
                                                            <TextField
                                                                {...fieldProps.field}
                                                                type="text"
                                                                fullWidth
                                                                label="API key"
                                                                error={form.touched.api_key && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                                helperText={form.touched.api_key && !form.isValid && fieldProps.meta.error}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <Field
                                                        name="secret_key"
                                                        children={(fieldProps: any) => (
                                                            <TextField
                                                                {...fieldProps.field}
                                                                type="password"
                                                                autoComplete="off"
                                                                fullWidth
                                                                label="Secret key"
                                                                error={form.touched.secret_key && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                                                helperText={form.touched.secret_key && !form.isValid && fieldProps.meta.error}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className={st.save_buttons}>
                                            <Button type="submit" color="primary" size="large" variant="contained" sx={{ width: "100%" }}>
                                                Integrate Exchange
                                            </Button>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Box>
                        {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre>*/}
                    </FormikProvider>
                </Grid>
            </Grid>

            <Popup>
                <Typography component="h1" variant="h3" className={stylePopup.title}>Disconnect Exchange</Typography>

                {successDisconnect ?
                    <>
                        <Typography component="h1" variant="h5" className={stylePopup.text}>
                            The Exchange has been successfully disconnected.
                        </Typography>

                        <Button type="button" color="primary" sx={{ mt: 6, width: "100%" }} variant="contained" onClick={closePopup}>
                            Close
                        </Button>
                    </>
                    :
                    <>
                        <Typography variant="button" className={stylePopup.text}>
                            Are you sure?
                        </Typography>

                        <Grid container spacing={2} pt={{ xs: 3, sm: 4, md: 6 }}>
                            <Grid item xs={12} sm={6}>
                                <Button type="button" color="primary" variant="outlined" onClick={closePopup} sx={{ width: "100%" }}>
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Button type="submit" color="primary" variant="contained" sx={{ width: "100%" }} onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Popup>
        </Box>
    );
});

export default Investment;
