import React from "react";
import { Outlet } from "react-router-dom";
import UIStore from "../../store/UIStore";
import classNames from "classnames";
import st from "./Auth.module.scss";
import { ReactComponent as InfoIcon } from "../../assets/images/icons/info.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross.svg";
import { observer } from "mobx-react";


const Auth: React.FC = observer(() => {
    const {successAlertContent, errorAlertContent, setSuccessAlertContent, setErrorAlertContent} = UIStore;

    return (
        <div className={st.auth}>
            <div className={st.auth_container}>
                {(successAlertContent || errorAlertContent) &&
                    <div className={classNames('alert', (errorAlertContent ? 'alert_error' : ''))}>
                        <div className={'alert__info'}>
                            <InfoIcon />
                            <div>{successAlertContent || errorAlertContent}</div>
                        </div>

                        <CrossIcon className={classNames('hover-stroke hover-stroke-deep', 'alert__close')}
                                   onClick={() => {successAlertContent ? setSuccessAlertContent('') : setErrorAlertContent('')}}
                        />
                    </div>
                }

                <Outlet />
            </div>
        </div>
    );
});

export default Auth;
