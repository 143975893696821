enum AuthRoutes {
    cabinet = "/cabinet",
}

enum NonAuthRoutes {
    home = "/",
    auth = "/auth",
    signIn = "/auth/signin",
    signUp = "/auth/signup",
    forgotPassword = "/auth/forgot-password",
    newPassword = "/auth/new-password",
    passwordReset = "/auth/reset-password",
    netWorth = "/net-worth",
    exchanges = "/exchanges",
    investment = "/investment",
    community = "/community",
    settings = "/settings",
}

export { AuthRoutes, NonAuthRoutes };
