import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

// styles
import st from "./Investment.module.scss";
import { getData } from "../../api/api";
import DataStore from "../../store/DataStore";


const InvestmentsLayout: React.FC = (() => {
    React.useEffect(() => {
        let isMounted = true;

        getData(`funds`)
            .then(({ data }) => {
                if (isMounted) {
                    DataStore.updateFunds(data);
                }
            })
            .catch(error => console.log({error}));

        return () => { isMounted = false };
    });

    return (
        <Box component="section" className={st.investments}>
            <Outlet />
        </Box>
    );
});

export default InvestmentsLayout;
