// @ts-nocheck
import React, { ReactNode } from "react";
import st from "./popup.module.scss";
import classNames from "classnames";
import { observer } from 'mobx-react';
import { Dialog, IconButton } from '@mui/material';
import { ReactComponent as CrossIcon } from "../../assets/images/icons/circle-cross.svg";
import Fade from '@mui/material/Fade';
import UIStore from "../../store/UIStore";

interface SimplePopupProps {
    children: ReactNode;
    className?: string;
    callback?: Function | undefined;
}

export const Popup: React.FC<SimplePopupProps> = observer(({
    children,
    className,
    callback = undefined,
}) => {
    const {
        popupActive,
        closePopup,
    } = UIStore;

    const onPopupClose = () => {
        closePopup();
        callback && callback();
    };

    return (
        <Dialog TransitionComponent={Fade} onClose={onPopupClose} maxWidth="100vw" open={popupActive} scroll="body" className={classNames(st.popup, className)}>
            <div className={st.container}>
                <IconButton className={st.close} onClick={onPopupClose}>
                    <CrossIcon />
                </IconButton>

                {children}
            </div>
        </Dialog>
    );
});
