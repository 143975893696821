// @ts-nocheck
import { makeAutoObservable } from "mobx";
import jwt_decode from "jwt-decode";
import BinanceImage from "../assets/images/exchanges/binance.png";
import KucoinImage from "../assets/images/exchanges/kucoin.png";
import OkexImage from "../assets/images/exchanges/okex.png";
import GateImage from "../assets/images/exchanges/gate.png";
import BitmexImage from "../assets/images/exchanges/bitmex.png";
import KrakenImage from "../assets/images/exchanges/kraken.png";
import BitfinexImage from "../assets/images/exchanges/bitfinex.png";
import CoinbaseImage from "../assets/images/exchanges/coinbase.png";
import GeminiImage from "../assets/images/exchanges/gemini.png";
import PoloniexImage from "../assets/images/exchanges/poloniex.png";


interface PieChart {
    "code": string | undefined,
    "name": string | undefined,
    "percentage": number | undefined,
    "usd_value": number | undefined,
}

interface MonthlyProfits {
    "year": number | undefined,
    "month": number | undefined,
    "balance_usdt": number | undefined,
    "balance_usdt_with_profit": number | undefined,
}

interface PortfolioProfits {
    "year": number | undefined,
    "month": number | undefined,
    "return_value": number | undefined,
}

interface Profits {
    "usd_amount": number | undefined,
    "usd_day_profit": number | undefined,
    "usd_day_profit_percentage": number | undefined,
    "usd_last_30_days_profit": number | undefined,
    "usd_last_30_days_profit_percentage": number | undefined,
    "usd_last_7_days_profit": number | undefined,
    "usd_last_7_days_profit_percentage": number | undefined,
    "usd_month_profit": number | undefined,
    "usd_month_profit_percentage": number | undefined,
    "usd_total_profit": number | undefined,
    "usd_total_profit_percentage": number | undefined,
}

interface Deals {
    "pair": string | undefined,
    "balance": number | undefined,
    "change_net": number | undefined,
    "change_net_percentage": number | undefined,
}

interface Exchanges {
    "market_code": string,
    "name": string,
    "api_key": string,
    "secret_key": string,
    "passphrase": string,
    connection_date?: string,
}


class DataStore {
    needDataUpdate = true;
    isUserAuthorized = false;
    user = {};
    userExchanges: Array<Exchanges> = [];
    userPieChart: Array<PieChart> = [];
    userMonthlyProfits: Array<MonthlyProfits> = [];
    userPortfolioProfits: Array<PortfolioProfits> = [];
    userProfits: Array<Profits> = [];
    userDeals: Array<Deals> = [];

    funds = [];
    currentFund = {};
    currentFundPerformance = [];
    currentFundAssets = [];
    currentFundTransactions = [];
    currentFundHistory = [];
    staticExchanges = [
        {
            id: "Binance",
            icon: BinanceImage,
            title: "Binance",
            text: "Integrate with Spot",
            market_code: "binance",
        },
        {
            id: "Kucoin",
            icon: KucoinImage,
            title: "Kucoin",
            text: "Integrate with Spot",
            market_code: "kucoin",
        },
        {
            id: "Okex",
            icon: OkexImage,
            title: "Okex",
            text: "Integrate with Spot",
            market_code: "okex",
        },
        {
            id: "Gate",
            icon: GateImage,
            title: "Gate.io",
            text: "Integrate with Spot",
            market_code: "gate_io",
        },
        {
            id: "Bitmex",
            icon: BitmexImage,
            title: "Bitmex",
            text: "Integrate with Spot",
            market_code: "bitmex",
        },
        {
            id: "Kraken",
            icon: KrakenImage,
            title: "Kraken",
            text: "Integrate with Spot",
            market_code: "kraken",
        },
        {
            id: "Bitfinex",
            icon: BitfinexImage,
            title: "Bitfinex",
            text: "Integrate with Spot",
            market_code: "bitfinex",
        },
        {
            id: "Coinbase",
            icon: CoinbaseImage,
            title: "Coinbase",
            text: "Integrate with Spot",
            market_code: "gdax",
        },
        {
            id: "BinanceUs",
            icon: BinanceImage,
            title: "Binance US",
            text: "Integrate with Spot",
            market_code: "binance_us",
        },
        {
            id: "Gemini",
            icon: GeminiImage,
            title: "Gemini",
            text: "Integrate with Spot",
            market_code: "gemini",
        },
        {
            id: "Poloniex",
            icon: PoloniexImage,
            title: "Poloniex",
            text: "Integrate with Spot",
            market_code: "poloniex",
        },
    ];

    constructor() {
        makeAutoObservable(this);
        let userId = '';
        let auth = false;
        if (localStorage.getItem("feasible_token")) {
            const {user_id: jwtId} = jwt_decode(localStorage.getItem("feasible_token"));
            // console.log('user_id', jwtId);
            userId = jwtId;
            auth = true;
        }
        this.user = {id: userId};
        this.isUserAuthorized = auth;
    }

    updateUser (value) {
        this.user = value;
    }

    resetData () {
        this.isUserAuthorized = false;
        this.needDataUpdate = true;
        this.user = {};
        this.userExchanges = [];
        this.userPieChart = [];
        this.userMonthlyProfits = [];
        this.userPortfolioProfits =[];
        this.userProfits = [];
        this.userDeals = [];
        this.funds = [];
        this.currentFund = {};
        this.currentFundPerformance = [];
        this.currentFundAssets = [];
        this.currentFundTransactions = [];
        this.currentFundHistory = [];
    }

    updateUserExchanges (value, needDataUpdate = false) {
        this.needDataUpdate = needDataUpdate;

        this.userExchanges = value;
    }

    updateUserPieChart (value) {
        this.userPieChart = value;
    }

    updateUserMonthlyProfits (value) {
        this.userMonthlyProfits = value;
    }

    updateUserPortfolioProfits (value) {
        this.userPortfolioProfits = value;
    }

    updateUserProfits (value) {
        this.userProfits = value;
    }

    updateUserDeals (value) {
        this.userDeals = value;
    }

    updateAuthorize (value) {
        this.isUserAuthorized = value;
    }

    updateNeedDataUpdate (value) {
        this.needDataUpdate = value;
    }

    updateFunds (value) {
        this.funds = value;
    }

    updateCurrentFund (value) {
        this.currentFund = value;
    }

    updateCurrentFundPerformance (value) {
        this.currentFundPerformance = value;
    }

    updateCurrentFundAssets (value) {
        this.currentFundAssets = value;
    }

    updateCurrentFundTransactions (value) {
        this.currentFundTransactions = value;
    }

    updateCurrentFundHistory (value) {
        this.currentFundHistory = value;
    }

}

export default new DataStore();
