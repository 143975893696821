// @ts-nocheck
import React, { useEffect, useState } from "react";
import st from "./userExchanges.module.scss";
import classNames from "classnames";
import { observer } from 'mobx-react';
import UIStore from "../../store/UIStore";
import DataStore from "../../store/DataStore";
import { Button, Grid, Typography } from "@mui/material";
import stylePopup from "../Popup/popup.module.scss";
import { Popup } from "../Popup";
import { getData, postData } from "../../api/api";


export const UserExchanges = observer(() => {
    const {
        openPopup,
        closePopup,
        setErrorAlertContentTime,
        setSuccessAlertContent,
        alertContentTime,
    } = UIStore;

    const [selectExchange, setSelectExchange] = useState(undefined);
    const [successDisconnect, setSuccessDisconnect] = useState(false);

    const handleSubmit = () => {
        if (selectExchange?.id) {
            postData(`funds/${selectExchange.bounded_fund_id}/unbind`, { exchange_id: selectExchange.id, fund_id: selectExchange.bounded_fund_id }, 'post')
                .then(({ data }) => {
                    setSuccessAlertContent("Exchange successfully separated from Fund");

                    getData(`users/${DataStore.user.id}/exchanges`)
                        .then(({data}) => {
                            DataStore.updateUserExchanges(data);
                            closePopup();
                        })
                        .catch(error => {
                            console.log({error});
                        });
                })
                .catch((errorMessage) => {
                        setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
                    }
                );
        }
    }

    useEffect(() => {
        let isMounted = true;

        if (DataStore.funds.length === 0) {
            getData('funds')
                .then(({ data }) => {
                    if (isMounted) {
                        DataStore.updateFunds(data);
                    }
                })
                .catch(error => console.log({error}));
        }

        return () => { isMounted = false };
    });

    return (
        <div className={st.exchanges}>
            {DataStore.userExchanges.map((item, index) => (
                <div className={st.exchange_info} key={'userExchanges' + index}>
                    <div className={st.exchange_title}>
                        {DataStore.staticExchanges.find((value) => value.market_code === item.market_code).title} {new Date(item?.connection_date).toLocaleString("en-GB", {
                        year: "numeric", month: "2-digit", day: "2-digit",
                    })}
                    </div>

                    {item.is_bound_to_fund &&
                        <div className={classNames(st.exchange_stop)}
                             onClick={(e) => {
                                 setSelectExchange(item);
                                 openPopup();
                                 setSuccessDisconnect(false);
                             }}
                        >
                            Stop Trading
                        </div>
                    }
                </div>
            ))}

            <Popup>
                <Typography component="h1" variant="h3" className={stylePopup.title}>Disconnect the exchange with
                    the {DataStore.funds.find(item => item.id === selectExchange?.bounded_fund_id)?.name}.</Typography>

                {successDisconnect ?
                    <>
                        <Typography component="h1" variant="h5" className={stylePopup.text}>
                            The Exchange has been successfully disconnected.
                        </Typography>

                        <Button type="button" color="primary" sx={{ mt: 6, width: "100%" }} variant="contained" onClick={closePopup}>
                            Close
                        </Button>
                    </>
                    :
                    <>
                        <Typography variant="button" className={stylePopup.text}>
                            Are you sure?
                        </Typography>

                        <Grid container spacing={2} pt={{ xs: 3, sm: 4, md: 6 }}>
                            <Grid item xs={12} sm={6}>
                                <Button type="button" color="primary" variant="outlined" onClick={closePopup} sx={{ width: "100%" }}>
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Button type="submit" color="primary" variant="contained" sx={{ width: "100%" }} onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Popup>
        </div>
    );
});
