import axios from "axios";


class mediaObjectApi {
    loadMedia = (file, userId) => {
        let formData = new FormData();
        formData.append('file', file);

        return axios({
            method: 'PUT',
            url:  `${axios.defaults.baseURL}/users/${userId}/info/avatar`,
            // data: file,
            data: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Content-Type': 'image/png',
                'jwt': localStorage.getItem('feasible_token'),
            },
        });
    }

    deleteMedia = (iri) => {
        return axios({
            method: 'DELETE',
            url: axios.defaults.baseURL + iri,
            headers: {
                'accept': '*/*',
            },
        });
    }

    deleteAvatar = (id) => {
        return axios({
            method: 'DELETE',
            url: axios.defaults.baseURL +`api/profiles/${id}/delete-avatar`,
            headers: {
                'accept': '*/*',
            },
        });
    }

}

export default new mediaObjectApi();
