// @ts-nocheck
import { Box, Grid } from "@mui/material";
import TotalInfo from "../TotalInfo/TotalInfo";
import st from "./NetWorth.module.scss";
import classNames from "classnames";
import ChartLayout from "../Chart/Layout";
import { ReactComponent as ArrowUpIcon } from "../../assets/images/icons/arrow-to-up.svg";
import { ReactComponent as DollarIcon } from "../../assets/images/icons/dollar.svg";
import { ReactComponent as TableIcon } from "../../assets/images/icons/table.svg";
import TableLayout from "../Table/TableLayout";
import TableGrid from "../Table/Table";
import React from "react";
import { useDispatch } from "react-redux";
import { changeHeaderButton } from "../../redux/actions/header";
import BarCharts from "../Chart/Bar";
import DataStore from "../../store/DataStore";
import { observer } from "mobx-react";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/icons/arrow-to-down.svg";
import { neatNumber } from "../../helpers/helpers";
import NumberFormat from "react-number-format";


const formatNumber = (value) => {
    return (
        <NumberFormat
            value={value}
            decimalScale={2}
            defaultValue={0}
            thousandSeparator
            displayType={'text'}
            prefix="$"
        />
    );
};


const NetWorth: React.FC = observer(() => {
    const dispatch = useDispatch();

    React.useEffect( () => {
        dispatch(changeHeaderButton('choose'));
    }, [dispatch]);

    return (
        <Grid component="section" container spacing={6}>
            <Grid item xs={12} sm={12} md={5} lg={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm="auto" md={12} className={st.total__item}>
                        <div className={st.total__big}>{formatNumber(DataStore.userProfits?.usd_total_profit)}</div>
                        <div className={st.total__small}>Total</div>
                    </Grid>

                    <Grid item xs={12} sm="auto" md={12} className={st.total__item}>
                        <div className={st.total__big}>{formatNumber(DataStore.userProfits?.usd_last_30_days_profit)}</div>
                        <div className={st.total__small}>Last 30 Days</div>
                    </Grid>

                    <Grid item xs={12} sm="auto" md={12}>
                        <div className={classNames(st.total__number,
                            (DataStore.userProfits?.usd_last_30_days_profit_percentage > 0 ?
                                st.total__number_up
                                : (DataStore.userProfits?.usd_last_30_days_profit_percentage < 0 ?
                                    st.total__number_down
                                    : '')))}
                        >
                            {DataStore.userProfits?.usd_last_30_days_profit_percentage > 0 ?
                                <ArrowUpIcon className={st.total__arrow} />
                                :
                                DataStore.userProfits?.usd_last_30_days_profit_percentage < 0 ?
                                    <ArrowDownIcon className={st.total__arrow} />
                                    :
                                    ""
                            }
                            <span>
                                {DataStore.userProfits?.usd_last_30_days_profit_percentage > 0 ? '+': ''}
                                {neatNumber(DataStore.userProfits?.usd_last_30_days_profit_percentage, true)}
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md ml={{ lg: -6 }}>
                <div className={st.total__title}>Your Total Profit</div>
                <div className={st.total__text}>You are doing great job this month! Keep going on 🔥</div>
            </Grid>

            <Grid item xs={5} sm={3} md={2}>
                <TotalInfo title="Bi-weekly"
                           balance={formatNumber(DataStore.userProfits?.usd_day_profit * 14)}
                           number={neatNumber(DataStore.userProfits?.usd_day_profit_percentage, true)}
                           toUp={DataStore.userProfits.usd_day_profit_percentage > 0}
                           toDown={DataStore.userProfits.usd_day_profit_percentage < 0}
                />
            </Grid>

            <Grid item xs={5} sm={3} md={2}>
                <TotalInfo title="Monthly"
                           balance={formatNumber(DataStore.userProfits?.usd_month_profit)}
                           number={neatNumber(DataStore.userProfits?.usd_month_profit_percentage, true)}
                           toUp={DataStore.userProfits.usd_month_profit_percentage > 0}
                           toDown={DataStore.userProfits.usd_month_profit_percentage < 0}
                />
            </Grid>

            <Grid item xs={12} pt={10}>
                <ChartLayout title="Net-Worth Stats" icon={<DollarIcon />} exchanges>
                    <Box className={st.barChart}>
                        <BarCharts data={DataStore.userMonthlyProfits} />
                    </Box>
                </ChartLayout>
            </Grid>

            <Grid item xs={12} pt={10}>
                <TableLayout title="Opened Deals" icon={<TableIcon />}>
                    <TableGrid data={DataStore.userDeals} />
                </TableLayout>
            </Grid>
        </Grid>
    );
});

export default NetWorth;
