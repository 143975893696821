// @ts-nocheck
import React from "react";
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import st from "./Layout.module.scss";
import { neatNumber } from "../../helpers/helpers";


const PieCharts = ({data}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [dataPie, setDataPie] = React.useState([]);

    React.useEffect(() => {
        if (data.length) {
            setDataPie(data.map((item, index) => {
                return {name: item.code, label: `${neatNumber(item.percentage, true)}`, value: item.percentage, fill: "white", fillOpacity: item.percentage/100}
            }));
        }
    }, [data]);

    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, outerRadius, fill, name, label } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? "start" : "end";
        const textX = ex + (cos >= 0 ? 1 : -1) * 12;

        return (
            <g>
                <rect x={textX > cx ? textX - 12 : textX - 65} y={ey - 20} width="75" height="45" fill="white" stroke="#D6E7ED" strokeWidth="2" />
                <rect x={textX > cx ? textX - 5 : textX - 60} y={ey - 10} width="8" height="8" fill={fill} />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <text x={textX > cx ? textX + 10 : textX - 5} y={ey} textAnchor={textAnchor} fill="#333">
                    {name}
                </text>
                <text x={textX > cx ? textX + 10 : textX - 5} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {label}
                </text>
            </g>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart className={st.pie_container}>
                <Pie data={dataPie} dataKey="value" label={renderActiveShape} cx="50%" cy="50%" innerRadius={matches ? 120 : 40} outerRadius={matches ? 170 : 70}>
                    {dataPie.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.fill} fillOpacity={entry.fillOpacity} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PieCharts;
