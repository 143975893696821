// @ts-nocheck
import { Box, Button, Grid, TextField, } from "@mui/material";
import { NonAuthRoutes } from "../../routes/routes";

// styles
import st from "./Investment.module.scss";
import { Link } from "react-router-dom";
import TotalInfo from "../TotalInfo/TotalInfo";
import React, { useState } from "react";
import DataStore from "../../store/DataStore";
import { observer } from "mobx-react";
import { Popup } from "../Popup";
import stylePopup from "../Popup/popup.module.scss";
import UIStore from "../../store/UIStore";
import classNames from "classnames";

import { ReactComponent as AlertIcon } from "../../assets/images/icons/alert-triangle.svg";
import { getData, postData } from "../../api/api";
import NumberFormat from "react-number-format";

const formatNumber = (value) => {
    return (
        <NumberFormat
            value={value}
            decimalScale={2}
            defaultValue={0}
            thousandSeparator
            displayType={'text'}
            prefix="$"
        />
    );
};


const Investments: React.FC = observer(() => {
    const {
        openPopup,
        alertContentTime, setErrorAlertContent, setErrorAlertContentTime, setSuccessAlertContent,
    } = UIStore;
    const [selectFund, setSelectFund] = useState(undefined);
    const [selectExchange, setSelectExchange] = useState(DataStore.userExchanges?.length ? DataStore.userExchanges[0] : undefined);

    const handleSubmit = () => {
        setSuccessAlertContent('', true);
        setErrorAlertContent('', true);

        postData(`funds/${selectFund.id}/bind`, { exchange_id: selectExchange.id, fund_id: selectFund.id }, 'post')
            .then(() => {
                setSuccessAlertContent("Exchange Successfully Allocated to Fund!");

                getData(`users/${DataStore.user.id}/exchanges`)
                    .then(({data}) => {
                        DataStore.updateUserExchanges(data);
                    })
                    .catch(error => {
                        console.log({error});
                    });
            })
            .catch((errorMessage) => {
                    setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
                }
            );
    }

    return (
        <Box aria-label="Investment table" mb={-8}>
            {DataStore.funds?.map((item, index) => (
                <Grid container spacing={2} key={item.id} className={st.list} mb={8}>
                    <Grid item xs={12} sm={6} lg={3} display="flex" justifyContent="center" flexDirection="column">
                        <h1 className={st.list__title}>{item.name}</h1>
                        <p className={st.list__date}>Starting Date: {new Date(item.starting_date).toLocaleString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', })}</p>
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3} display="flex" justifyContent="center" flexDirection="column">
                        <Box component="p" mb={2}>
                            <span className={st.list__value}>{formatNumber(item.allocation)}</span>
                            <span className={st.list__label}>Allocation</span>
                        </Box>
                        <Box component="p">
                            <span className={st.list__value}>{item.members}</span>
                            <span className={st.list__label}>Members</span>
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={4} lg={1.7}>
                        <TotalInfo noIcon title="Weekly"
                                   balance={formatNumber(item.daily_change_net)}
                                   number={item.daily_change_percentage?.toFixed(2) ?? ''}
                                   toUp={item.daily_change_percentage > 0}
                                   toDown={item.daily_change_percentage < 0}
                        />
                    </Grid>

                    <Grid item xs={6} sm={4} lg={1.7}>
                        <TotalInfo noIcon title="Monthly"
                                   balance={formatNumber(item.montly_change_net)}
                                   number={item.montly_change_percentage?.toFixed(2) ?? ''}
                                   toUp={item.montly_change_percentage > 0}
                                   toDown={item.montly_change_percentage < 0}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} lg={2.3} display="flex" alignItems="center">
                        <Grid container spacing={1} alignItems="center" justifyContent={{ xs: "flex-end", lg: "center" }}>
                            <Grid item xs="auto">
                                <Link to={`${NonAuthRoutes.investment}/${item.id}`}>
                                    <Button variant="text" size="small">Find more</Button>
                                </Link>
                            </Grid>
                            <Grid item xs="auto">
                                {/*<Link to={`${NonAuthRoutes.investment}/deposit/${item.id}`}>*/}
                                    <Button variant="outlined"
                                            onClick={() => {
                                                setSelectFund(item);
                                                setSelectExchange(DataStore.userExchanges?.[0]);
                                                openPopup();
                                            }}
                                    >
                                        Connect Exchange
                                    </Button>
                                {/*</Link>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ))}

            <Popup className="popup_large">
                <div className={classNames(stylePopup.text, stylePopup.text_2)}>
                    You are about to invest in:
                </div>

                <div className={classNames(stylePopup.text, stylePopup.text_3)} style={{marginTop: '9px'}}>
                    {selectFund?.name}
                </div>

                <div className={classNames(stylePopup.text, stylePopup.text_2)} style={{marginTop: '45px', marginBottom: '13px'}}>
                    Select Exchange
                </div>

                {(DataStore.userExchanges.length > 0) &&
                    <TextField
                        onChange={
                            (e) => {
                                setSelectExchange(DataStore.userExchanges.find(item => item.market_code === e.target.value));
                            }}
                        fullWidth
                        name="userExchange"
                        select
                        variant="outlined"
                        SelectProps={{
                            native: true,
                        }}
                        className="select"
                    >

                        {DataStore.userExchanges.map((item, index) => {
                            return (
                                !item.is_bound_to_fund &&
                                    <option value={item.market_code} key={index}
                                            style={{ backgroundImage: `url(${DataStore.staticExchanges.find((value) => value.market_code === item.market_code).icon})` }}>
                                        {DataStore.staticExchanges.find((value) => value.market_code === item.market_code).title} {new Date(item.connection_date).toLocaleString("en-GB", {
                                        year: "numeric", month: "2-digit", day: "2-digit",
                                    })}
                                    </option>
                            );
                        })}
                    </TextField>
                }

                <Button type="submit" color="primary" variant="contained" sx={{ width: "100%", mt: 5.5 }}
                        onClick={handleSubmit}
                        className={DataStore.userExchanges.length === 0 ? "disabled" : ''}
                >
                    Submit
                </Button>

                <div className={st.warning_text}>
                    <AlertIcon />

                    <div className={classNames(stylePopup.text, stylePopup.text_4)}>
                        Please consider that trading is at your own risk. The risk of loss in trading cryptocurrencies, even when done via an
                        automated
                        trading system, can be substantial. By confirming, you agree with our Terms and Conditions.
                    </div>
                </div>
            </Popup>
        </Box>
    );
});

export default Investments;
