// @ts-nocheck
import * as React from "react";
import { Field, FormikProvider, useFormik } from 'formik';
import validator from "validator";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { NonAuthRoutes } from "../../../routes/routes";
import {postData} from "../../../api/api";
import { Link } from 'react-router-dom';

import st from "../Auth.module.scss";
import logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import UIStore from "../../../store/UIStore";
import DataStore from "../../../store/DataStore";
import { randomIntFromInterval } from '../../../helpers/helpers'
import key from "../../../assets/images/key.jpeg";
import password from "../../../assets/images/password.jpeg";

const imageKey = randomIntFromInterval(1, 2);
const SignIn: React.FC = () => {
    let navigate = useNavigate();
    const { alertContentTime, setErrorAlertContent, setErrorAlertContentTime} = UIStore;

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.email) {
            errors.email = "Required";
        }

        if (values.email && !validator.isEmail(values.email)) {
            errors.email = "Invalid email";
        }

        if (!values.password) {
            errors.password = "Required";
        }

        if (values.password && values.password.length < 6) {
            errors.password = "At least 6 symbols";
        }

        return errors;
    }

    const form = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validate: validate,
        onSubmit: (values, { setErrors }) => {
            setErrorAlertContent('', true);
            postData('sign_in', values, 'post')
                .then(({ data }) => {
                    console.log('data', data);
                    if (data.user?.id && data.jwt) {
                        DataStore.updateAuthorize(true);
                        DataStore.updateNeedDataUpdate(true);
                        DataStore.updateUser(data.user);
                        localStorage.setItem("feasible_token", data.jwt);
                        navigate("/", { replace: true });
                    } else {
                        console.log('data');
                    }
                })
                .catch((errorMessage) => {
                        console.log('errorMessage', errorMessage);
                        setErrorAlertContentTime(JSON.stringify(errorMessage), alertContentTime);
                    }
                );
        },
    });

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid item xs={12} sm={8} md={5}
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        mt: { xs: 2, md: 5 },
                        mx: { xs: 3, md: 10, lg: 20 },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div className={st.logo}>
                        <Link to={NonAuthRoutes.home}>
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <Typography component="h1" variant="h3" mb={2}>
                        Sign in
                    </Typography>

                    <Typography component="p" variant="subtitle1" mb={2}>
                        Sign in into your account to check your stats
                    </Typography>

                    <FormikProvider value={form}>
                        <Box component="form" noValidate onSubmit={form.handleSubmit} sx={{ mt: 1 }} className={st.form}>
                            <Field
                                name="email"
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        type="text"
                                        fullWidth
                                        label="Email Address"
                                        error={form.touched.email && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.email && !form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />

                            <Field
                                name="password"
                                children={(fieldProps: any) => (
                                    <TextField
                                        {...fieldProps.field}
                                        type="password"
                                        fullWidth
                                        label="Password"
                                        error={form.touched.password && !form.isValid ? Boolean(fieldProps.meta.error) : undefined}
                                        helperText={form.touched.password && !form.isValid && fieldProps.meta.error}
                                    />
                                )}
                            />

                            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />

                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                Sign In
                            </Button>

                            <Grid container spacing={2}>
                                <Grid item textAlign="center" xs={12}>
                                    <Typography variant="body2">
                                        Don't have an account?{" "}
                                        <Link to={NonAuthRoutes.signUp} className={st.link}>
                                            {"Sign Up"}
                                        </Link>
                                    </Typography>
                                </Grid>
                                <Grid item textAlign="center" xs={12}>
                                    <Typography variant="body2">
                                        <Link to={NonAuthRoutes.forgotPassword} className={st.link}>
                                            {"Forgot Password?"}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        {/*<pre style={{color: "saddlebrown", }}>{JSON.stringify(form.values, 0, 2)}</pre>*/}
                    </FormikProvider>
                </Box>
            </Grid>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${imageKey === 1 ? key : password })`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
        </Grid>
    );
};

export default SignIn;
