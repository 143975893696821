import * as React from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
// import Badge from "@mui/material/Badge";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import { stringAvatar } from "../../helpers/helpers";
import { AppBar, Avatar, Box, Button, Drawer, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from "@mui/material";
import { NonAuthRoutes } from "../../routes/routes";
import useMediaQuery from "@mui/material/useMediaQuery";
import DataStore from "../../store/DataStore";

import st from "./Header.module.scss";
import logo from "../../assets/images/logo.png";
import { ReactComponent as CommunityIcon } from "../../assets/images/icons/community.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/icons/dashboard.svg";
import { ReactComponent as ExchangesIcon } from "../../assets/images/icons/exchanges.svg";
import { ReactComponent as InvestmentIcon } from "../../assets/images/icons/investment.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/icons/logout.svg";
import { ReactComponent as NetWrothIcon } from "../../assets/images/icons/net-wroth.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/settings.svg";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link, NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { defaultTheme } from "../../assets/themes/primaryMuiTheme";
import { useSelector } from "react-redux";
import { observer } from "mobx-react";


const menu = [
    {
        icon: <DashboardIcon />,
        link: NonAuthRoutes.home,
        name: "Dashboard",
    },
    {
        icon: <NetWrothIcon />,
        link: NonAuthRoutes.netWorth,
        name: "Net-Worth",
    },
    {
        icon: <ExchangesIcon />,
        link: NonAuthRoutes.exchanges,
        name: "Exchanges",
    },
    {
        icon: <InvestmentIcon />,
        link: NonAuthRoutes.investment,
        name: "Investment",
    },
    {
        icon: <CommunityIcon />,
        link: NonAuthRoutes.community,
        disabled: true,
        name: "Community",
    },
    {
        icon: <SettingsIcon />,
        link: NonAuthRoutes.settings,
        name: "Settings",
    },
];

interface Props {
    window?: () => Window;
}

const buttonList: any = {
    "choose": {
        name: "Connect Exchange",
        link: NonAuthRoutes.investment,
    },
    "deposit": {
        name: "Deposit Funds",
        link: `${NonAuthRoutes.investment}/deposit`,
    },
    "more": {
        name: "Invest More",
        link: NonAuthRoutes.investment,
    },
};

const Header: React.FC = observer((props: Props) => {
    const [button, setButton] = React.useState({name: "", link: ""});

    const selector = useSelector(({ header }: any) => {
        return buttonList[header.button]
    });

    React.useEffect(() => {
        setButton(selector);
    }, [selector]);

    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const matches = useMediaQuery(`(min-width: ${defaultTheme.breakpoints.values.sm + 1}px )`);
    const [openMobile, setOpenMobile] = React.useState(false);
    const [open, setOpen] = React.useState(!matches);
    const navigate = useNavigate();

    function handleInvestment() {
        navigate(button.link);
    }

    const toggleDrawer = () => {
        setOpenMobile(!openMobile);
        setOpen(!open);
    };

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogoutClick = () => {
        DataStore.resetData();
        localStorage.removeItem("feasible_token");
    }

    const user = {
        avatar: null,
        name: "Dave Dev",
    };

    return (
        <>
            <AppBar position="fixed" className={st.header} color={"transparent"}>
                <Toolbar disableGutters sx={{ width: "100%" }}>
                    <div className={st.header__logo}>
                        <Link to={NonAuthRoutes.home}>
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <div className={st.header__burger}>
                        <IconButton onClick={toggleDrawer} aria-label="menu" sx={{ display: { md: "none" } }}>
                            <MenuIcon sx={{ width: "32px", height: "32px" }} />
                        </IconButton>
                    </div>

                    <Button
                        onClick={handleInvestment}
                        variant="contained"
                        sx={{ display: { xs: "none", sm: "inline-flex" }, mr: 5, width: 250 }}
                    >
                        {button.name}
                    </Button>

{/*                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsNoneIcon />
                        </Badge>
                    </IconButton>*/}

                    <Box sx={{ flexGrow: 0 }} ml={3}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            {user.avatar ? (
                                <Avatar alt={user.name} src={user.avatar} className={st.header__avatar} />
                            ) : (
                                // <Avatar {...stringAvatar(user.name)} className={st.header__avatar} />
                                <AccountCircleOutlinedIcon sx={{ color: "#fff", width: "44px", height: "44px" }} />
                            )}
                        </IconButton>

                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Link to={NonAuthRoutes.settings}>
                                    <Typography component="span" color="primary" textAlign="center">
                                        Settings
                                    </Typography>
                                </Link>
                            </MenuItem>

                            <MenuItem onClick={handleLogoutClick}>
                                <Typography component="span" color="primary" textAlign="center">
                                    Logout
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer
                container={container}
                variant="temporary"
                open={openMobile}
                onClose={toggleDrawer}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", md: "none" },
                }}
                className={st.sidebar}
            >
                <div className={st.header__burger_close}>
                    <IconButton onClick={toggleDrawer} aria-label="menu" sx={{ display: { md: "none" } }}>
                        <CloseIcon sx={{ width: "32px", height: "32px" }} />
                    </IconButton>
                </div>

                <List component="nav">
                    {menu.map((item) => {
                        return (
                            <NavLink
                                to={item.link}
                                aria-disabled={item.disabled}
                                key={item.link}
                                className={({ isActive }) => (isActive ? classNames(st.sidebar__link, st.sidebar__link_active) : st.sidebar__link)}
                            >
                                <ListItemButton onClick={toggleDrawer}>
                                    <ListItemIcon sx={{ minWidth: { xs: "35px", lg: "56px" } }}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={`${item.name} ${item.disabled ? "(soon)" : ""}`} />
                                </ListItemButton>
                            </NavLink>
                        );
                    })}
                    <ListItemButton onClick={handleLogoutClick}>
                        <ListItemIcon sx={{ minWidth: { xs: "35px", lg: "56px" } }}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign Out" />
                    </ListItemButton>
                </List>

                <Button onClick={handleInvestment} variant="contained" sx={{ mt: 5 }}>
                    {button.name}
                </Button>
            </Drawer>

            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", md: "block" },
                }}
                open
                className={st.sidebar}
            >
                <List component="nav">
                    {menu.map((item) => {
                        return (
                            <NavLink
                                to={item.link}
                                aria-disabled={item.disabled}
                                key={item.link}
                                className={({ isActive }) => (isActive ? classNames(st.sidebar__link, st.sidebar__link_active) : st.sidebar__link)}
                            >
                                <ListItemButton>
                                    <ListItemIcon sx={{ minWidth: { sm: "50px", md: "35px", lg: "56px" } }}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={`${item.name} ${item.disabled ? "(soon)" : ""}`} />
                                </ListItemButton>
                            </NavLink>
                        );
                    })}
                    <ListItemButton onClick={handleLogoutClick}>
                        <ListItemIcon sx={{ minWidth: { sm: "50px", md: "35px", lg: "56px" } }}>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign Out" />
                    </ListItemButton>
                </List>
            </Drawer>
        </>
    );
});

export default Header;
