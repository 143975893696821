// @ts-nocheck

export const formatTime = (time: number) => {
    time = Math.max(time, 0);

    const sec = Math.floor((time / 1000) % 60)
        .toString()
        .padStart(2, "0");
    const min = Math.floor((time / 1000 / 60) % 60)
        .toString()
        .padStart(2, "0");
    const hrs = Math.floor((time / 1000 / 60 / 60) % 24)
        .toString()
        .padStart(2, "0");

    return `${hrs} : ${min} : ${sec}`;
};

export const stringAvatar = (name: string) => {
    return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
};


export const randomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
};

export const objectToArray = (value: any) => {
    if (typeof value === "object" && !Array.isArray(value) && value !== null) {
        return Object.values(value);
    }
    return value;
};

export const isObject = (value: any) => {
    return typeof value === "object" && !Array.isArray(value) && value !== null;
};

export const neatNumber = (value: any, isPercent = false, isZero = false) => {
    let formatValue = value ? value.toFixed(2) : ((value === 0 || isZero) ? 0 : '');
    if (value && isPercent) {
        formatValue += '%';
    }
    return formatValue;
};
